import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import client from '../../utils/feathers';
import properCase from '../../utils/proper-case';

import './styles.module.scss';

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = async value => {
	const inputValue = value.trim().toLowerCase();
	const inputLength = inputValue.length;

	// return inputLength === 0 ? [] : languages.filter(lang =>
	// 	lang.name.toLowerCase().slice(0, inputLength) === inputValue
	// );

	if(inputLength === 0)
		return [];

	const data = (await client.service('people').find({
		query: {
			q: value
		}
	})).data;

	if(data.length === 0)
		return [
			{ isAddNew: true }
	  	];

	return data;
};


export class PersonLookup extends Component {
	constructor() {
		super();

		// Autosuggest is a controlled component.
		// This means that you need to provide an input value
		// and an onChange handler that updates this value (see below).
		// Suggestions also need to be provided to the Autosuggest,
		// and they are initially empty because the Autosuggest is closed.
		this.state = {
			value: '',
			suggestions: []
		};
	}

	componentDidMount() {
		if (this.props.giveResetValueCallback)
			this.props.giveResetValueCallback(() => this.setState({ value: "" }));
	}

	onChange = (event, { newValue, method }) => {
		this.setState({
			value: newValue
		});

		if(method === 'enter') {

		}
	};

	// Autosuggest will call this function every time you need to update suggestions.
	// You already implemented this logic above, so just use it.
	onSuggestionsFetchRequested = async ({ value }) => {
		this.setState({
			suggestions: await getSuggestions(value)
		});
	};

	// Autosuggest will call this function every time you need to clear suggestions.
	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		});
	};


	// When suggestion is clicked, Autosuggest needs to populate the input
	// based on the clicked suggestion. Teach Autosuggest how to calculate the
	// input value for every given suggestion.
	getSuggestionValue = suggestion => {
		if (suggestion.isAddNew) {
			return properCase(this.state.value);
		}
		return properCase(suggestion.name);
	};

	// Use your imagination to render suggestions.
	renderSuggestion = suggestion => {
		if (suggestion.isAddNew) {
			return (
				<span>
					[+] Add new: <strong>{properCase(this.state.value)}</strong>
				</span>
			);
		}
		return (
			<div>
				{properCase(suggestion.name)}
			</div>
		);
	};

	onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
		// console.log("[onSuggestionSelected]", { suggestion, suggestionValue });
		if (this.props.onChange)
			this.props.onChange(suggestion, suggestionValue);
	};

	render() {
		const { value, suggestions } = this.state;

		// Autosuggest will pass through all these props to the input.
		const inputProps = {
			id:          'person-checkin',
			placeholder: 'Type the name of a person',
			className:   'form-control text-center',
			value,
			onChange: this.onChange,
			autoComplete: "off"
		};

		// Finally, render it!
		return (
			<Autosuggest
				suggestions={suggestions}
				highlightFirstSuggestion={true}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={this.getSuggestionValue}
				onSuggestionSelected={this.onSuggestionSelected}
				renderSuggestion={this.renderSuggestion}
				inputProps={inputProps}
			/>
		);
	}
}
