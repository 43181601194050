import React, { Component } from 'react'
import { Form, FormGroup, Input, ButtonGroup, Button, Row, Container, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';

import SEO from '../components/seo';
import Layout from '../components/layout'
// import Footer from '../components/footer'
import styles from './checkin.module.scss';

import Loading from '../components/loading-spinner';

import PaymentMethodEditor from '../components/payment-method-edit';
import { PersonLookup } from '../components/person-lookup';

import { FeathersList } from '../utils/feathers-list';
import client from '../utils/feathers';
import properCase from '../utils/proper-case';
import firstName from '../utils/first-name';
import windowGlobal from '../utils/window-global';

import def from '../../../core/db/defs/payment-method';

const PaymentTypes = def.default.types;

const DEFAULT_CLASS_PRICE = 12;
const DEFAULT_EXCLUDED_PAYMENTS = 'privates';

class CheckinPage extends Component {

	constructor() {
		super();
		this.state = {
			checkins: [],
			numWanted: {},
			person: null,
			selectedEvents: [],
			events: [],
			payment: null,
			newEventName: "",
			loadPending: false,
			paymentMethod: "cc",
			paymentMethods: Object.values(PaymentTypes),
		}

		// window.page = this;
	}

	setPending(flag) {
		this.setState({ loadPending: flag });
	}

	componentWillReceiveProps() {

		// If user clicks "Checkin" in navbar, clear current person and return to search
		const lstate = this.props.location.state || {};
		if (lstate.fromNavbar) {
			lstate.fromNavbar = false;
			this.resetState();
		}
	}


	componentDidMount() {
		// Load list of classes
		this.eventsDb = new FeathersList('events', events => {
			this.setState({
				events,
				// If only one class, auto-select
				selectedEvents: events.length === 1 ? [ events[0] ] : []
			});
			this.setPending(false);
		}, {
			isDeactivated: false,
			// query params sent to server
			$sort: {
				name: -1
			}
		});

		// // Not really used or needed - only for the first draft to show list at bottom
		// this.checkinsDb = new FeathersList('checkins', checkins => {
		// 	this.setState({
		// 		checkins
		// 	});
		// 	this.setPending(false);
		// }, {
		// 	// query params sent to server
		// 	$sort: {
		// 		createdBy: -1
		// 	}
		// });

		// Move keyboard focus back to search box
		setTimeout( () => {
			$('#person-checkin').focus();
		}, 100);
	}

	componentWillUnmount() {
		// Stop listening to changes
		this.eventsDb.off();
		// this.checkinsDb.off();
	}

	eventClicked(event) {
		$('button:focus').blur();

		let selectedEvents = this.state.selectedEvents || [];

		if (selectedEvents.find(_ => _.id == event.id))
			selectedEvents = selectedEvents.filter(_ => _.id !== event.id);
		else {
			if(event.singleCheckin && selectedEvents.length > 0) {
				alert(`\nSorry, you need to check in to ${event.name} by itself.\n\n` +  
					`If another class is selected, you can't check in to ${event.name} at the same time.`);

				setTimeout(() => $('button:focus').blur(), 100);
				return;
			}

			// Reset num wanted
			if(!this.state.numWanted)
				this.state.numWanted = {};

			this.state.numWanted[event.id] = 1;

			selectedEvents.push(event);

			if((event.excludedPaymentTypes || '').includes(this.state.paymentMethod)) {
				alert(`The selected class doesn't take ${this.state.paymentMethod.toUpperCase()} as payment - resetting payment method to CASH.`);
				this.setState({ paymentMethod: 'cash' });
			}

			// Focus on new input
			setTimeout( () => $('#num-wanted-' + event.id).focus(), 100);
		}

		this.setState({
			selectedEvents,
			numWanted: this.state.numWanted
		});

		this.updateCanCheckin();
	}

	updateCanCheckin() {
		this.updateComputedCost();

		// Need setTimeout because state.selectedEvents doesn't update instantly
		setTimeout(() => {
			// console.log("[updateCanCheckin]", this.state.selectedEvents);
			this.setState({
				canCheckin: this.state.selectedEvents && 
					this.state.selectedEvents.length > 0 && 
					this.state.paymentMethod && 
					this.state.person
			});
		}, 10);
	}

	updateComputedCost() {
		// Need setTimeout because state.selectedEvents doesn't update instantly
		setTimeout(() => {
			let costAllocationPending = 0;
			this.state.availMethods && 
				Object.values(this.state.availMethods).forEach(avail => {
					if(avail && avail.purchasedToday === 'yes') {
						costAllocationPending += parseFloat(avail.purchasePrice);
					}	
				});

			console.log("[updateComputedCost]", this.state.selectedEvents, costAllocationPending);
			// TODO: calc # punches etc based on paymentMethod
			const isDollars = ['cash','cc'].includes(this.state.paymentMethod);
			if(isDollars) {
				let cost = this.state.selectedEvents.reduce((sum, val) => sum + (parseFloat(val.price) * (this.state.numWanted[val.id] || 1)), 0);
				if(costAllocationPending > 0) {
					cost += costAllocationPending;
					costAllocationPending = 0;
				}
				this.setState({
					computedCost: '$' + cost,
					computedCostNumber: cost,
					cashWillPay:  '$' + cost,
				});
			} else {
			// if(this.state.paymentMethod == 'punch') {
				const punches = this.state.selectedEvents.reduce((sum, val) => sum + (this.state.numWanted[val.id] || 1), 0);
				this.setState({
					computedCost: punches + (punches == 1 ? ' punch' : ' punches'),
					computedCostNumber: punches,
					cashWillPay: 0,
				});
			}
		}, 10);
	}

	cashWillPay(val) {
		val = (val + "").trim().replace(/^\$*/g, '');
		this.setState({ cashWillPay: '$' + val });
	}

	addNewEvent() {
		const name = properCase((this.state.newEventName + "").trim());
		if(name) {
			this.setPending(true);
			this.eventsDb
				.create({ name, price: DEFAULT_CLASS_PRICE, excludedPaymentTypes: DEFAULT_EXCLUDED_PAYMENTS })
				.then(event => this.eventClicked(event));

			this.setState({ newEventName: "" });
		} else {
			alert("Type something in the event box to add it");
		}
	}

	personChosen(person, name) {
		// Set here even if isAddNew so we can render spinner if desired
		this.setPerson(person);

		if(person.isAddNew) {
			client.service('people')
				.create({ name: properCase(name) })
				.then(person => {
					this.setPerson(person);
					// console.log("new person:",person);
				});
		}
	}

	async setPerson(person) {
		// This changes screens...
		this.setState({ person });

		// If new, setPerson will be called again once create() returns
		if(person.isAddNew)
			return;

		await Promise.all([
			this.getLastCheckin(person),
			this.getPaymentMethods(person)
		]);

		// Show/hide button
		this.updateCanCheckin();
	}

	async getLastCheckin(person) {
		// Get last checkin
		const lastCheckin = await client.service('people')
			// RPC to core/db/models/person to exec getLastCheckin
			.get(`${person.id}:getLastCheckin`);

		// If found, update state with hints from last checkin
		if(lastCheckin) {
			this.setState({
				paymentMethod: lastCheckin.paymentType,
				selectedEvents: [], // lastCheckin.event ],
				lastCheckin,
				hasLastCheckin: true,
			});
		} else {
			this.setState({
				hasLastCheckin: false,
				paymentMethod: null,
				lastCheckin: null,
				selectedEvents: this.state.events.length === 1 ? [ this.state.events[0] ] : [],
			});
		}
	}

	async getPaymentMethods(person) {
		// Get last checkin
		const list = await client.service('people')
			// RPC to core/db/models/person to exec getLastCheckin
			.get(`${person.id}:getPaymentMethods`);

		// If found, update state with hints from last checkin
		if(list && list.length) {
			const paymentMethod = 
				this.state.paymentMethod ?
				this.state.paymentMethod :
				list[0].typeId;

			const availMethods = {};
			list.forEach(type => {
				availMethods[type.typeId] = type;
			});

			// console.log("[getPaymentMethods] availMethods:", availMethods);

			this.setState({
				paymentMethod,
				availMethods
			});
		} else {
			this.setState({
				availMethods: {}
			});
		}
	}

	async storeCheckin() {
		if(!this.state.person)
			return alert("Select a person first, please");

		if(!this.state.paymentMethod)
			return alert("Please choose a payment method, please");

		if(!this.state.selectedEvents.length)
			return alert("Please choose at least one class");

		let costAllocationPending = 0, wasPurchasedToday = false;
		await Promise.all(Object.values(this.state.availMethods).map(async avail => {
			if(avail && avail.purchasedToday === 'yes') {
				costAllocationPending += parseFloat(avail.purchasePrice);
				// We record purchase price on the tx above if purchasedToday, so clear this for future checkins with this method
				avail = await client.service('payment-methods').patch(avail.id, { purchasedToday: 'no' });
				wasPurchasedToday = true;
			}	
		}));

		console.log("storeCheckin: costAllocationPending=", costAllocationPending);

		
		const events = this.state.selectedEvents,
			multiCheckinId = Date.now();
		
		// let avail = this.state.availMethods[this.state.paymentMethod];

		if(this.state.paymentMethod === 'cash') {
			// const cashWillPay = parseFloat((this.state.cashWillPay + "").trim().replace(/^\$*/g, ''));
			const cashOwe = parseFloat(this.state.computedCostNumber);
			const input = windowGlobal.prompt(
				`
				${firstName(this)} owes \$${cashOwe} for this check-in.
				
				Enter the amount of cash they gave you to calculate change:`, cashOwe);

			if(!input) {
				alert("Checkin canceled per your request - if you didn't mean to, click check-in again and click OK to the 'Change' prompt.");
				return;
			}
			const cashWillPay = parseFloat((input + "").trim().replace(/^\$*/g, ''));
			const changeOwed = cashWillPay - cashOwe;

			if(changeOwed < 0) {
				return alert("That's not enough, please try again.");
			}

			if(changeOwed > 0) {
				const conf = windowGlobal.confirm(`Give them:

					 \$${changeOwed}
					 
				in change then press OK to continue checkin. (The payed \$${cashWillPay}, the checkin costs \$${cashOwe})`);

				if(!conf) {

					alert("Checkin canceled per your request - if you didn't mean to, click check-in again and click OK to the 'Change' prompt.");
					return;
				}
			}

		}
		

		this.setPending(true);

		const numWanted = this.state.numWanted || {};
		
		let avail = this.state.availMethods[this.state.paymentMethod],
			punches = avail ? parseFloat(avail.remainingPunches) : null;


		const checkinList = await Promise.all(
			events.map(async event => {
				const num = numWanted[event.id] || 1;
				let	cost = 0;

				if(avail) {
					punches = punches - num
					avail = await client.service('payment-methods').patch(avail.id, { remainingPunches: punches });
					console.log("[checkin] patched avail pmt method:", {avail, punches, num});
					
					// Store patched value so UI buttons update 
					// NB not needed actually since we reset person
					// this.state.availMethods[this.state.paymentMethod] = avail;
				} else {
					cost = parseFloat(event.price);
				}

				if(costAllocationPending) {
					cost += costAllocationPending;
					costAllocationPending = 0;
				}

				const p = Promise.resolve();
				const args = {
					multiCheckinId,
					event: event.id,
					person: this.state.person.id,
					paymentType: this.state.paymentMethod,
					amountPayed: cost,
					storedPaymentMethod: avail ? avail.id : null,
					cardPurchased: avail && wasPurchasedToday ? true : false,
					notes: this.state.otherNotes,
				};

				for(let i=0; i<num;i++) {
					p.then( () => client.service('checkins').create(args).then(checkin => {
						console.log("created:", {avail, checkin, wasPurchasedToday});
						return checkin;
					}) );
				}

				return p;
			}));

		// console.log({checkinList});
		this.setState({
			checkinSuccess: checkinList.length > 0			
		});
		this.resetState();

		// Hide save notification after ~3sec
		setTimeout( () => {
			this.setState({ checkinSuccess: false });
		}, 3000);

	}

	resetState() {
		// Reset state
		this.setState({
			numWanted: {},
			person: null,
			selectedEvents: this.state.events.length === 1 ? [ this.state.events[0] ] : [],
			events: this.state.events,
			paymentMethod: "cc",
			otherNotes: "",
			// availMethods: this.state.availMethods,
		});

		// Reload incase pricing was changed for last checkin
		this.eventsDb.updateQuery();
		
		this.updateCanCheckin();

		this.setPending(false);

		// Reset the <PersonLookup>
		if (this.resetPersonCallback)
			this.resetPersonCallback();

		// Move keyboard focus back to search box
		setTimeout( () => {
			$('#person-checkin').focus();
		}, 100);
	}

	async updatePerson(field, value) {
		const pid = this.state.person.id;
		if(field == 'name') {
			value = properCase(value);
		}

		if(!this._personChanges)
			this._personChanges = {};

		this._personChanges[field] = value;
		
		// const person = await client.service('people').patch(pid, { [field]: value });
		console.log("[updatePerson]", { [field]: value });

		// this.setState({ person });
	}

	async savePersonChanges(flag) {
		if(flag === undefined)
			flag = true;
		this.setState({ editPersonFlag: false });

		if(flag) {
			const person = await client.service('people').patch(this.state.person.id, this._personChanges);
			// console.log("[updatePerson]", { [field]: value });

			this.setState({ person });
		}

		this._personChanges = {};
	}

	eventCountChanged(event, num) {
		if(!this.state.numWanted)
			this.state.numWanted = {};
		
		let want = parseInt(num);
		if(!want || isNaN(want))
			want = 0;
		
		this.state.numWanted[event.id] = want;

		this.setState({
			numWanted: this.state.numWanted
		});

		// console.log({event})
		this.updateComputedCost();
	}

	async choosePaymentMethod(method) {
		const typeId = method.id;
		let avail = this.state.availMethods[typeId];

		let rejected = false;
		(this.state.selectedEvents || []).forEach(event => {
			if(event.excludedPaymentTypes.includes(typeId)) {
				rejected = event;
				
			}
		});

		if(rejected) {
			alert(`You can't use ${typeId.toUpperCase()} to pay for ${rejected.name} - please choose a different payment method`);
			setTimeout(() => $('button:focus').blur(), 100);
			return;
		}

		if(typeId === 'cash') {
			setTimeout( () => $('#cash-input-box').focus(), 100);
		} else
		if(method.stored !== false) {
			if(!avail) {
				const choice = windowGlobal.confirm(
					`There's no ${method.name} on file for ${firstName(this)}. Do you want to add a ${method.name} to ${firstName(this)}'s file right now?`
				);

				if(choice) {
					// alert("TODO");
					avail = await client.service('payment-methods').create({ 
						person: this.state.person.id,
						typeId,
						remainingPunches: 10, // populate in case
						startDate:        new Date(),
						// purchaseDate:  new Date(),
						purchasedToday:  "yes",
						purchasePrice:    100,
					});
					this.state.availMethods[typeId] = avail; //{ ...avail, purchasedToday: "yes" };
					this.setState({ availMethods: this.state.availMethods });

					this.editPaymentMethod(avail);
				} else {
					//return alert("Canceled...");
					return;
				}
			} else
			if(typeId == 'punch' && avail.remainingPunches <= 0) {
				const choice = windowGlobal.confirm(
					`${firstName(this)} has used up all the punches on their punch card. Do you want to add more punches to their card right now?`
				);
				if(choice) {
					// avail = await client.service('payment-methods').patch(avail.id, { remainingPunches: 10 });

					// this.state.availMethods[typeId] = avail;
					// this.setState({ availMethods: this.state.availMethods });

					this.editPaymentMethod(avail);
				} else {
					return;
				}
				// const choice = prompt("")
			} else {
				console.log("Type OK");
			}
		}

		this.setState({ paymentMethod: typeId });
		this.updateCanCheckin();
		setTimeout(() => $('button:focus').blur(), 100);
	}

	async savePaymentMethodEdits(opts={ save: true, cancel: false, delete: false}) {

		const srv = client.service('payment-methods');

		const data = this.state.editPaymentMethod;
		if(opts.save) {
			// if(data.isNew) {
			// 	delete data.isNew;
			// 	data.person = this.state.person.id;
			// 	this.paymentMethodsDb.create(data);
			// } else {
				console.log("[savePaymentMethodEdits=patch]", { data });
				const avail = await srv.patch(data.id, data);
				this.state.availMethods[data.typeId] = avail;

			// }
		}
		else
		if(opts.delete) {
			await srv.remove(data.id);
			this.state.availMethods[data.typeId] = null;
		}

		this.setState({
			availMethods: this.state.availMethods,
			editPaymentMethod: null
		})
	}

	editPaymentMethod(row) {
		// console.warn("[editPaymentMethod]", row);
		// if(row.isNew) {
		// 	row = Object.assign({
		// 		typeId: "punch",
		// 		remainingPunches: 10,
		// 		purchaseDate:     new Date(),
		// 		purchasePrice:    100,
		// 	}, row);
		// }

		if(!row.purchasedToday)
			row.purchasedToday = "no";

		this.setState({
			editPaymentMethod: {...row, checkin: true},
		});
	}

	editPriceForEvent(event, evt) {
		evt.preventDefault();

		const newPrice = Math.ceil(parseFloat(windowGlobal.prompt("Cost:", Math.ceil(parseFloat(event.price)))));
		if(!isNaN(newPrice)) {
			event.price = newPrice;
			this.setState({ events: [ ...this.state.events ]});
			setTimeout(() => this.updateComputedCost(), 1);
			console.log("Updated ", event);
		}

		return false;
	}

	render() { return (
		<Layout page="checkin" authRequired={true}>{auth =>
			<div className={styles.page}>
				<SEO title="Check In" keywords={[`gatsby`, `application`, `react`]} />
				<section>
					<Col className={styles.mainCol} sm="12" md="10" lg="9" style={{ margin: "0 auto"}}>
						{this.state.loadPending && <Loading/> }

						{this.state.editPaymentMethod && 
							<PaymentMethodEditor
								createMode={this.state.editPaymentMethod.isNew}
								model={this.state.editPaymentMethod}
								onSaved={() => this.savePaymentMethodEdits({ save: true })}
								onCancel={() => this.savePaymentMethodEdits({ cancel: true })}
								onDelete={() => this.savePaymentMethodEdits({ delete: true })}
							/>
						}

						<h1 
							// style={{marginTop:0}}>
							style={ !this.state.person ? {marginTop: "8rem"} : { marginTop: "0rem" }}>
							Check In {this.state.person &&
							this.state.person.id && <span>
								{this.state.person.name}
						</span>}</h1>

						{this.state.person && 
							<ButtonGroup style={{margin:"-.5rem 0 .5rem"}}>
								<Button color="secondary"
									onClick={() => { this.setState({ person: null }); setTimeout( () => $('#person-checkin').focus(), 100) }}>
									<FontAwesomeIcon icon={["fas", "chevron-left"]} />
									{" "}
									Choose someone else
								</Button>
								
								{!this.state.editPersonFlag &&
									<Button onClick={() => this.setState({ editPersonFlag: true })}>
										<FontAwesomeIcon icon={["fas", "edit"]} />
										{" "}
										Edit {firstName(this)}
									</Button>
								}
								{this.state.editPersonFlag &&
									<Button onClick={() => this.savePersonChanges(false)}>
										<FontAwesomeIcon icon="times" />
										{" "}
										Cancel
									</Button>
								}
							</ButtonGroup>
						}

						{!this.state.person && 
							<Col md="7" style={{margin:"0 auto"}}>
								<PersonLookup
									onChange={(person, name) => this.personChosen(person, name)}
									giveResetValueCallback={cb => this.resetPersonCallback = cb}
								/>
							</Col>
						}

						{this.state.checkinSuccess && !this.state.person && <div 
							className="alert alert-success col-md-6" style={{margin:"3rem auto"}}>
							<h3 className="mb-0 mt-0">
								<FontAwesomeIcon icon="check"/>{" "}Check in saved!
							</h3>
						</div>}

						


						{/* {this.state.person && (<>
							{this.state.person.isAddNew && <Loading/>}
							{this.state.person.id && <h3>
								<i>Selected:</i>
								{this.state.person.name}
							</h3>}
						</>)} */}

						{this.state.person && <>
							
							{this.state.editPersonFlag && <>
								<Form className={styles.personForm}>
									<FormGroup>
									<label>{firstName(this)}'s Name</label>
										<Input
											defaultValue={this.state.person.name  || ""}
											onChange={e => this.updatePerson('name', e.target.value)}/>
									</FormGroup>
									<FormGroup>
										<label>{firstName(this)}'s Email</label>
										<Input
											type="email"
											id="email-edit"
											defaultValue={this.state.person.email || ""}
											onChange={e => this.updatePerson('email', e.target.value)}/>
									</FormGroup>
									<FormGroup>
										<label>Notes about {firstName(this)}</label>
										<Input
											type="text"
											id="notes-edit"
											defaultValue={this.state.person.notes || ""}
											onChange={e => this.updatePerson('notes', e.target.value)}/>
									</FormGroup>
								</Form>

								<Button onClick={() => this.savePersonChanges(true)}>
									<FontAwesomeIcon icon="save" />
									{" "}
									Save Changes
								</Button>
							
							</>}

							{!this.state.editPersonFlag && <>

								<div className={styles.alertBox}>
									
									{!this.state.person.email && 
										<div className="alert alert-secondary d-flex justify-content-center flex-column">
											<a href='#'
												style={{margin:0}}
												onClick={() => {
													this.setState({ editPersonFlag: true });
													setTimeout(() => $('#email-edit').focus(), 100);
													return false;
												}}>
												<FontAwesomeIcon icon="exclamation-circle" /> {firstName(this)} <b>doesn't have an email</b> - please add an email to their file by clicking here.
											</a>
										</div>
									}


									{this.state.person.notes && <>
										<div className="alert alert-danger d-flex justify-content-center flex-column">
											<a href='#'
													style={{margin:0, color: "inherit"}}
													onClick={() => {
														this.setState({ editPersonFlag: true });
														setTimeout(() => $('#notes-edit').focus(), 100);
														return false;
													}}>
												<FontAwesomeIcon icon="exclamation" /> Note: <b>{this.state.person.notes}</b>
											</a>
										</div>
									</>}

									{!this.state.hasLastCheckin && <>
										<div className="alert alert-primary d-flex justify-content-center flex-column" style={{minWidth:"18rem"}}>
											<span>
												<FontAwesomeIcon icon="star" /> Hey, {firstName(this)} <b>hasn't been here before</b> - make them feel welcome!
											</span>
										</div>
									</>}

									{this.state.hasLastCheckin && <>
										<div className="alert alert-success d-flex justify-content-center flex-column" style={{minWidth:"18rem"}}>
											<span>
												<FontAwesomeIcon icon="user-check" /> Welcome back, {firstName(this)}! <br/> This is checkin # <b>{parseInt(this.state.lastCheckin._count)+1}</b> for them!
											</span>
										</div>
									</>}

								</div>


								<Row>
									<Col md="6">
										<h4 style={{ marginTop: ".25rem"}}>
											{this.state.selectedEvents.length > 0 ? <>
												{this.state.selectedEvents.length > 0 &&
												<span>{this.state.selectedEvents.length} selected = {this.state.computedCost}
												</span>}
											</> : <>
												Select an Event
											</>}
										</h4>
										

										<div className="text-left mt-2 button-list">
											{this.state.events.map(event => {
												event._active = this.state.selectedEvents.find(_ => _.id == event.id) ? true :false;

												return <div className={styles.buttonWrap} key={event.id}>
													{event._active &&
														<div className={styles.inputWrap}>
															<Input
																defaultValue={1}
																min={1}
																step={1}
																type="number"
																id={"num-wanted-" + event.id}
																onChange={e => this.eventCountChanged(event, e.target.value)}
																onFocus={e => e.target.select()}
															/>
															<a href='#'
																title="Change the price of this class/event"
																onClick={evt => this.editPriceForEvent(event, evt)}
																className={styles.priceEdit}>
																$ ...
															</a>
														</div>
													}
													<Button
														className="text-left bl-primary"
														block
														onClick={() => this.eventClicked(event)}
														active={event._active}>
															<span>
																<FontAwesomeIcon icon={event._active ? ['fas', "check-circle"] : ['far', "circle"] } />
																&nbsp;
																{event.name}
															</span>
															<span className='meta'>
																${event.price}
															</span>
													</Button>
												</div>;
											})}
										</div>

										<Input placeholder=" +  Add new event..."
											className="mt-2 mb-2"
											value={this.state.newEventName}
											onKeyUp={e => e.which === 13 && this.addNewEvent()}
											onChange={e => this.setState({ newEventName: e.target.value })}
										/>
									</Col>
									<Col md="6">

										<h4 style={{ marginTop: ".25rem"}}>
											Payment Method
										</h4>

										<div className="text-left mt-2 button-list">
											{this.state.paymentMethods.map(method => {
												method._active = this.state.paymentMethod == method.id;
												const avail = (this.state.availMethods || {})[method.id];
												
												return <div className={styles.buttonWrap + " " + styles.buttonWrapRight} key={method.id}>
													
													{method._active && <>
														{
															method.id === 'cash' ?
															// <Input
															// 	// defaultValue={this.state.computedCostNumber}
															// 	value={this.state.cashWillPay}
															// 	type="text"
															// 	id="cash-input-box"
															// 	onChange={e => this.cashWillPay(e.target.value)}
															// 	onFocus={e => e.target.select()}
															// /> :
															<></> :
															method.stored !== false ?
															<Button color="success"
																onClick={() => this.editPaymentMethod(avail)}
																className="form-control bl-edit-btn"
																title="Edit Payment Method">
																<FontAwesomeIcon icon="edit" />
															</Button> :
															<></>
													}</>}
													
													<Button
														className="text-left bl-primary"
														block
														key={method.id}
														onClick={event => { 
															this.choosePaymentMethod(method);
															event.target.blur()
														}}
														active={method._active}>
															<span>
																<FontAwesomeIcon icon={method._active ? ['fas', "check-circle"] : ['far', "circle"] } />
																&nbsp;
																{method.name}
															</span>
															<span className='meta pay-type'>
																{avail ?
																	<>{
																		['punch','privates'].includes(method.id) ?
																			<b className="good">{avail.remainingPunches} left</b>
																		: 
																		method.id === 'promopunch' ?
																			<b className="good">{avail.promoName} / {avail.remainingPunches} left</b>
																		:
																			<b className="good">{avail.details}</b>
																	}</> :
																	<>{method && method.stored !== false ?
																		<b className="bad">NOT AVAIL</b>
																		:
																		<></>
																	}</>
																}
															</span>
													</Button>
												</div>
											})}
										</div>

										<Input placeholder="Other payment information"
											className="mt-2 mb-2"
											defaultValue={this.state.otherNotes}
											onChange={e => this.setState({ otherNotes: e.target.value })}
										/>
									</Col>
								</Row>

								<Button
									block
									size="lg"
									color="secondary"
									className={
										[styles.checkinBtn,
											this.state.canCheckin ? 
												styles.checkinBtnEnabled : 
												""
										].join(" ")
									}
									onClick={() => this.storeCheckin()}
								>
									<FontAwesomeIcon icon="save" />
									&nbsp;
									Check In
									{" "}<b>({this.state.computedCost})</b>
								</Button>
							</>}
						</>}
					</Col>
				</section>
				{/* <Footer/> */}
			</div>
		}</Layout>
	)}
}

export default CheckinPage;
